html {
  background: #000000;
  background-image: linear-gradient(black, black);
  font-family: Lora;
  font-weight: 200;
}

body {
  /* Reseting the default here */
  margin: 0;
  padding: 0;
}

#article_wrapper {
  position: relative;
  height: 20000px; /* Length of the interactive scroll article */
}

#canvas_wrapper {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  width: 100vw;
  height: 100vh;
}

.model_canvas_wrapper{
  height: 100vh;
}

.Link{
  border: white solid;
  border-radius: 2px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: "Lora";
  font-weight: 400;
  font-size: 1.5rem;
}

a {
  color: rgb(255, 236, 239);
  text-decoration: none;
  z-index: 50;
}

a:visited {
  color: rgb(255, 236, 239);
  text-decoration: none;
  z-index: 50;
}


.scroll_slide {
  color: "white";
  position: "absolute";
  width: "50vw";
  padding-left: "20px";
  z-index: "50";
}

h2 {
  padding-left: 0 !important;
}

.section1 {
  color: white;
  position: absolute;
  font-family: Unna, Auton;
  width: 50vw;
  font-size: 4rem;
  word-spacing:0.1rem;
  padding-left: 5rem !important;
  z-index: 50;
}

.section5 {
  color: white;
  width: 100vw;
  height: 200vh;
  position: absolute;
  text-align: left;

  padding-left: 0 !important;
  z-index: 50;
}

.hover{
  transition: all 0.3s ease;
  background-color: black;
  color:white;
}

.hover:hover{
  background-color: #ffffff;
  color:black;
  scale: 1;
}